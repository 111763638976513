const HealthSquareIcon = () => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="14.5" y="5.99414" width="40" height="40" rx="8" fill="#DBE6D7" />
    <rect
      x="6.5"
      y="13.9941"
      width="40"
      height="40"
      rx="8"
      fill="white"
      stroke="#3F6655"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M22.4968 21.9941H30.5032V29.9909H38.5V37.9973H30.5032V45.9941H22.4968V37.9973H14.5V29.9909H22.4968V21.9941Z"
      fill="#DBE6D7"
    />
  </svg>
);

export default HealthSquareIcon;
