import { Box } from '@mui/material';
import React from 'react';

import {
  AetnaIcon,
  BlueCrossIcon,
  CignaIcon,
  UHCIcon,
} from '@/components/Icons';

type Props = {
  height?: number;
};
export const InsurancePartners = (props: Props) => {
  const height = props.height || 36;
  return (
    <Box display="flex" justifyContent="space-evenly" gap="10px">
      <CignaIcon height={height} />
      <AetnaIcon height={height} />
      <BlueCrossIcon height={height} />
      <UHCIcon height={height} />
    </Box>
  );
};
