const PersonWithGlobeIcon = () => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.7857 23.4465L36.3477 24.6862C35.3082 24.8594 34.5815 25.8095 34.6864 26.858L37.1483 51.4774C37.3366 53.3603 35.858 54.9941 33.9657 54.9941C32.5127 54.9941 31.2423 54.0148 30.8725 52.6096L26.7545 36.9612C26.6045 36.3913 26.0893 35.9941 25.5 35.9941C24.9107 35.9941 24.3955 36.3913 24.2455 36.9612L20.1275 52.6096C19.7577 54.0148 18.4873 54.9941 17.0343 54.9941C15.142 54.9941 13.6634 53.3603 13.8517 51.4774L16.3136 26.858C16.4185 25.8095 15.6918 24.8594 14.6523 24.6862L7.21427 23.4465C5.64799 23.1855 4.5 21.8303 4.5 20.2424C4.5 18.4484 5.95431 16.9941 7.74829 16.9941H43.2517C45.0457 16.9941 46.5 18.4484 46.5 20.2424C46.5 21.8303 45.352 23.1855 43.7857 23.4465Z"
      fill="#DBE6D7"
      stroke="#DBE6D7"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <circle
      cx="25.5"
      cy="10.9941"
      r="6"
      fill="#DBE6D7"
      stroke="#DBE6D7"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M51.7857 23.4465L44.3477 24.6862C43.3082 24.8594 42.5815 25.8095 42.6864 26.858L45.1483 51.4774C45.3366 53.3603 43.858 54.9941 41.9657 54.9941C40.5127 54.9941 39.2423 54.0148 38.8725 52.6096L34.7545 36.9612C34.6045 36.3913 34.0893 35.9941 33.5 35.9941C32.9107 35.9941 32.3955 36.3913 32.2455 36.9612L28.1275 52.6096C27.7577 54.0148 26.4873 54.9941 25.0343 54.9941C23.142 54.9941 21.6634 53.3603 21.8517 51.4774L24.3136 26.858C24.4185 25.8095 23.6918 24.8594 22.6523 24.6862L15.2143 23.4465C13.648 23.1855 12.5 21.8303 12.5 20.2424C12.5 18.4484 13.9543 16.9941 15.7483 16.9941H51.2517C53.0457 16.9941 54.5 18.4484 54.5 20.2424C54.5 21.8303 53.352 23.1855 51.7857 23.4465Z"
      fill="white"
      stroke="#3F6655"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <circle
      cx="33.5"
      cy="10.9941"
      r="6"
      fill="white"
      stroke="#3F6655"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M39.5 25.9941C39.5 29.3078 36.8137 31.9941 33.5 31.9941C32.8729 31.9941 32.2682 31.8979 31.7 31.7195C29.2657 30.9549 27.5 28.6807 27.5 25.9941C27.5 22.6804 30.1863 19.9941 33.5 19.9941C34.1271 19.9941 34.7318 20.0904 35.3 20.2688C37.7343 21.0334 39.5 23.3076 39.5 25.9941Z"
      fill="white"
    />
    <path
      d="M39.5 25.9941C39.5 29.3078 36.8137 31.9941 33.5 31.9941C32.8729 31.9941 32.2682 31.8979 31.7 31.7195M39.5 25.9941C39.5 23.3076 37.7343 21.0334 35.3 20.2688M39.5 25.9941H35.3M27.5 25.9941C27.5 22.6804 30.1863 19.9941 33.5 19.9941C34.1271 19.9941 34.7318 20.0904 35.3 20.2688M27.5 25.9941H31.7M27.5 25.9941C27.5 28.6807 29.2657 30.9549 31.7 31.7195M35.3 20.2688V25.9941M35.3 25.9941H31.7M31.7 25.9941V31.7195"
      stroke="#3F6655"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default PersonWithGlobeIcon;
