import { Box, Grid } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { InsurancePartners } from '@/components/LandingPage/InsurancePartners';
import { colors } from '@/constants/colors';
import { LANDING_PAGE_OPTIONS } from '@/constants/constants';
import type { IReferrer } from '@/models/Types';
import { FormContainer } from '@/styles/shared';
import { useAppState } from '@/utils/AppState';

import { ForwardIcon } from '../Icons';
import CheckWaveIcon from '../Icons/CheckWaveIcon';
import {
  MainTitle,
  SubTitle,
} from '../InitialBook/InitialBookFormHeading.styled';
import Logo from '../Logo';
import LandingPageFooter from './LandingPageFooter';

const LandingPage = () => {
  const router = useRouter();
  const { dispatch } = useAppState();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [selected, setSelected] = useState<string>('');
  const [referrer, setReferrer] = useState<IReferrer | null>(null);
  const [firstLoad, setFirstLoad] = useState(false);

  const handleNext = () => {
    dispatch({ type: 'FLOW_PICKED', code: selected });

    const params: any = new URLSearchParams();

    if (referrer?.id) params.append('referrer_id', referrer?.id);

    if (referrer?.name) params.append('referrer_name', referrer?.name);

    if (referrer?.id) params.append('origin', 'true');

    if (router?.query?.token) params.append('token', router.query?.token);

    if (selected === 'lpg_dti') {
      router.push(
        `/direct-to-initial/schedule${
          params?.size || params?.toString().length
            ? `?${params.toString()}`
            : ''
        }`
      );
    }

    if (selected === 'lpg_dc') {
      router.push(
        `/discovery/schedule${
          params?.size || params?.toString().length
            ? `?${params.toString()}`
            : ''
        }`
      );
    }
  };

  useEffect(() => {
    if (router?.query?.referrer_name || router?.query?.referrer_id) {
      const queryReferrer = {
        id: router.query.referrer_id || '',
        name: router.query.referrer_name || '',
      } as IReferrer;

      setReferrer(queryReferrer);
      dispatch({ type: 'REFERRER_DATA', data: queryReferrer });
    }

    setFirstLoad(true);
    // reset some state values
    dispatch({ type: 'UPDATE_APPOINTMENT', data: null });
    dispatch({ type: 'SET_CALENDAR_START', data: null });
    dispatch({ type: 'SAVE_LEAD', data: null });
    dispatch({ type: 'SAVE_LEAD_EMAIL', data: null });
    dispatch({ type: 'SAVE_LEAD_USER', data: null });
    dispatch({ type: 'SAVE_LEAD_USER_ID', data: null });
    dispatch({ type: 'RESET_DETAILS', data: null });
  }, []);

  useEffect(() => {
    handleNext();
  }, [selected]);

  return (
    <FormContainer
      id="landing-page-container"
      data-testid="landing-page-container"
      width="636px"
    >
      <Logo
        data-testid="logo-id"
        textAlign="center"
        sx={{ padding: '0 66px 28px' }}
      />
      <Box
        id="cy-content-form"
        data-testid="cy-content-form"
        style={{
          paddingTop: isMobile && firstLoad ? '10px' : '20px',
          paddingBottom: '19px',
          paddingLeft: isMobile && firstLoad ? '10px' : '40px',
          paddingRight: isMobile && firstLoad ? '10px' : '40px',
        }}
      >
        <MainTitle
          data-testid="main-title"
          // for some reason this title is 48px in design all others are 36px
          style={{
            fontSize: isMobile && firstLoad ? '32px' : '48px',
            marginTop: isMobile && firstLoad ? '1px' : '36px',
          }}
        >
          Let&apos;s Get Started
        </MainTitle>
        <SubTitle data-testid="sub-title">
          Award-winning virtual nutrition counseling that is sustainable,
          inclusive, and grounded in science.
        </SubTitle>
      </Box>
      <Box
        sx={{
          paddingTop: '20px',
          textAlign: 'center',
        }}
      >
        <Grid
          container
          data-testid="main-container"
          paddingLeft={isMobile && firstLoad ? '16px' : '54px'}
          paddingRight={isMobile && firstLoad ? '16px' : '54px'}
          lineHeight={isMobile && firstLoad ? '20px' : '24px'}
        >
          {LANDING_PAGE_OPTIONS.map((item, i) => (
            <Grid
              key={i}
              item
              xs={12}
              data-testid={`item-container-${i}`}
              sx={{
                outline: `2px solid ${colors.slate}`,
                borderRadius: '6px',
                cursor: 'pointer',
                background:
                  selected === item.value ? colors.lightGreen : colors.white,
                height: 'auto',
                overflow: 'hidden',
                marginBottom: '18px',
                ':last-child': {
                  marginBottom: 0,
                },
                ':hover': {
                  background: colors.lightGreen,
                },
              }}
              onClick={() => setSelected(item.value)}
              height={isMobile && firstLoad ? '162px' : '144px'}
              textAlign="left"
            >
              <Box
                sx={{
                  padding: '24px',
                }}
              >
                <Box
                  data-testid={`lp-option-label-${i}`}
                  sx={{
                    fontSize: isMobile && firstLoad ? 16 : 18,
                    fontWeight: 700,
                    color: colors.green,
                    marginBottom: '16px',
                  }}
                >
                  {item.name}{' '}
                  <Box
                    component="span"
                    sx={{
                      position: 'relative',
                      left: '8px',
                      top: '6px',
                    }}
                  >
                    <ForwardIcon />
                  </Box>
                </Box>
                <Box fontSize={isMobile && firstLoad ? 14 : 16}>
                  {item.description}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box
          data-testid="accept-insurance-note"
          marginTop="25px"
          paddingLeft={isMobile && firstLoad ? '16px' : '54px'}
          paddingRight={isMobile && firstLoad ? '16px' : '54px'}
          lineHeight={isMobile && firstLoad ? '20px' : '24px'}
        >
          <Box
            display="flex"
            fontWeight={400}
            justifyContent="center"
            textAlign="center"
            gap="8px"
          >
            <Box>
              <CheckWaveIcon height="20" width="20" />
            </Box>
            <Box fontSize={isMobile && firstLoad ? 14 : 16}>
              93% of our patients receive partial or full insurance coverage
            </Box>
          </Box>
          <Box marginTop="24px" marginBottom="24px">
            <InsurancePartners />
          </Box>
          <hr />
          <LandingPageFooter />
        </Box>
      </Box>
    </FormContainer>
  );
};

export default LandingPage;
