import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import GlobeIcon from '@/components/Icons/GlobeIcon';
import HealthSquareIcon from '@/components/Icons/HealthSquareIcon';
import PersonWithGlobeIcon from '@/components/Icons/PersonWithGlobeIcon';
import { colors } from '@/constants/colors';

const LandingPageFooter = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  return (
    <Box
      textAlign="center"
      marginTop="12px"
      height={isMobile && firstLoad ? '170px' : '136px'}
      display="flex"
      justifyContent="space-evenly"
      color={colors.slate}
      lineHeight="normal"
    >
      <Box width="calc(100% / 3)">
        <GlobeIcon />
        <Box textAlign="center" fontSize={12}>
          Diverse <br />
          Providers
        </Box>
      </Box>
      <Box width="calc(100% / 3)">
        <HealthSquareIcon />
        <Box textAlign="center" fontSize={12}>
          Personalized, <br />
          collaborative care
        </Box>
      </Box>
      <Box width="calc(100% / 3)">
        <PersonWithGlobeIcon />
        <Box textAlign="center" fontSize={12}>
          Focus on <br />
          long-term health
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageFooter;
