import React from 'react';

import LandingPage from '@/components/LandingPage/LandingPage';
import { COMPANY_NAME } from '@/constants/constants';
import { Meta } from '@/layouts/Meta';
import { Main } from '@/templates/Main';

const Index = () => {
  return (
    <Main
      meta={
        <Meta
          title={`${COMPANY_NAME} - Let's Get Started`}
          description="Schedule your first appointment."
        />
      }
    >
      <LandingPage />
    </Main>
  );
};

export default Index;
