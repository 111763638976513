const GlobeIcon = () => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.5 46.9941C46.098 46.9941 55.5 37.5921 55.5 25.9941C55.5 14.3962 46.098 4.99414 34.5 4.99414C22.902 4.99414 13.5 14.3962 13.5 25.9941C13.5 37.5921 22.902 46.9941 34.5 46.9941Z"
      fill="#DBE6D7"
    />
    <path
      d="M25.5 54.9941C36.5457 54.9941 45.5 46.0398 45.5 34.9941C45.5 23.9484 36.5457 14.9941 25.5 14.9941C14.4543 14.9941 5.5 23.9484 5.5 34.9941C5.5 46.0398 14.4543 54.9941 25.5 54.9941Z"
      fill="white"
      stroke="#3F6655"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M16.4342 41.4692L16.8586 44.4395C16.9504 45.0821 17.2481 45.6776 17.7071 46.1366L19.7256 48.155C19.9078 48.3372 20.0657 48.5421 20.1956 48.7647L23.0928 53.7314C23.345 54.1637 23.8077 54.4295 24.3081 54.4295C24.9959 54.4295 25.5829 53.9322 25.6959 53.2538L26.3561 49.2927C26.4501 48.729 26.7031 48.2038 27.0854 47.779L29.096 45.5451C30.1633 44.3591 30.1156 42.5451 28.9874 41.4169L25.5375 37.967C24.8829 37.3124 23.9627 36.9966 23.0441 37.1115L17.9559 37.7475C17.0373 37.8623 16.1171 37.5466 15.4625 36.892L14.427 35.8564C13.8992 35.3287 13.8527 34.4886 14.3189 33.9058L14.3743 33.8366C14.9444 33.124 16.0337 33.141 16.5812 33.8711L16.735 34.0762C16.9018 34.2986 17.1636 34.4295 17.4416 34.4295C17.9927 34.4295 18.4093 33.9304 18.3107 33.3882L17.7812 30.476C17.6051 29.5074 17.9154 28.5141 18.6115 27.818L20.3787 26.0508C20.7765 25.653 21.3161 25.4295 21.8787 25.4295H22.9393C23.5251 25.4295 24 24.9546 24 24.3688C24 24.0875 23.8883 23.8177 23.6893 23.6188L21.0607 20.9901C20.4749 20.4043 20.4749 19.4546 21.0607 18.8688C21.342 18.5875 21.5 18.206 21.5 17.8081V15.9295C21.5 15.6032 21.1813 15.3942 20.8639 15.4695C12.0537 17.5606 5.5 25.4797 5.5 34.9295C5.5 35.8107 5.557 36.6787 5.66751 37.5299C5.69022 37.7047 5.89478 37.7715 5.9855 37.6203C6.48459 36.7885 7.65194 36.6901 8.28325 37.4266L9.13275 38.4177C9.67104 39.0457 10.4986 39.3464 11.3145 39.2104L12.9712 38.9343C14.6324 38.6574 16.1961 39.802 16.4342 41.4692Z"
      fill="#DBE6D7"
    />
    <path
      d="M27.173 14.9984C26.5754 14.949 26 15.3299 26 15.9295V16.5691C26 17.1313 26.158 17.6823 26.456 18.1591L28.0392 20.6922C28.3259 21.1509 28.8286 21.4295 29.3694 21.4295C30.0446 21.4295 30.644 20.9974 30.8575 20.3569L31.0991 19.6323C31.3385 18.914 32.0107 18.4295 32.7679 18.4295C33.5076 18.4295 34.1683 17.9667 34.4211 17.2715C34.4644 17.1523 34.4119 17.0194 34.298 16.9635C32.1168 15.8934 29.7122 15.2087 27.173 14.9984Z"
      fill="#DBE6D7"
    />
    <path
      d="M38.8809 20.0648C38.2534 19.4996 37.2827 19.6771 36.8351 20.3933C36.6161 20.7437 36.5 21.1486 36.5 21.5618V22.0948C36.5 22.865 35.9432 23.5223 35.1834 23.6489C34.2762 23.8001 33.6909 24.6929 33.9139 25.5852L34.0811 26.254C34.3273 27.2387 35.2121 27.9295 36.2271 27.9295H39.4715C39.8211 27.9295 40.1681 27.8683 40.4967 27.7488L43.5252 26.6476C43.6119 26.616 43.65 26.5165 43.6108 26.433C42.4689 24.0033 40.8525 21.8407 38.8809 20.0648Z"
      fill="#DBE6D7"
    />
    <path
      d="M45.2181 31.5644C45.121 30.9913 44.6028 30.5619 44.0513 30.7457L40.9618 31.7755C40.656 31.8775 40.3356 31.9295 40.0132 31.9295H37.7426C36.947 31.9295 36.1839 32.2455 35.6213 32.8081L34.8787 33.5508C34.3161 34.1134 34 34.8765 34 35.6721V37.6868C34 38.4825 34.3161 39.2455 34.8787 39.8081L35.1791 40.1086C35.7047 40.6342 36.4176 40.9295 37.1609 40.9295C38.5154 40.9295 39.6761 41.8982 39.9184 43.2308L40.3613 45.6666C40.4523 46.1669 40.669 46.636 40.991 47.0296C41.1057 47.1697 41.3206 47.1679 41.4302 47.0237C43.9841 43.6651 45.5 39.4743 45.5 34.9295C45.5 33.7827 45.4035 32.6585 45.2181 31.5644Z"
      fill="#DBE6D7"
    />
    <path
      d="M16.4342 41.4692L16.8586 44.4395C16.9504 45.0821 17.2481 45.6776 17.7071 46.1366L19.7256 48.155C19.9078 48.3372 20.0657 48.5421 20.1956 48.7647L23.0928 53.7314C23.345 54.1637 23.8077 54.4295 24.3081 54.4295C24.9959 54.4295 25.5829 53.9322 25.6959 53.2538L26.3561 49.2927C26.4501 48.729 26.7031 48.2038 27.0854 47.779L29.096 45.5451C30.1633 44.3591 30.1156 42.5451 28.9874 41.4169L25.5375 37.967C24.8829 37.3124 23.9627 36.9966 23.0441 37.1115L17.9559 37.7475C17.0373 37.8623 16.1171 37.5466 15.4625 36.892L14.427 35.8564C13.8992 35.3287 13.8527 34.4886 14.3189 33.9058L14.3743 33.8366C14.9444 33.124 16.0337 33.141 16.5812 33.8711L16.735 34.0762C16.9018 34.2986 17.1636 34.4295 17.4416 34.4295C17.9927 34.4295 18.4093 33.9304 18.3107 33.3882L17.7812 30.476C17.6051 29.5074 17.9154 28.5141 18.6115 27.818L20.3787 26.0508C20.7765 25.653 21.3161 25.4295 21.8787 25.4295H22.9393C23.5251 25.4295 24 24.9546 24 24.3688C24 24.0875 23.8883 23.8177 23.6893 23.6188L21.0607 20.9901C20.4749 20.4043 20.4749 19.4546 21.0607 18.8688C21.342 18.5875 21.5 18.206 21.5 17.8081V15.9295C21.5 15.6032 21.1813 15.3942 20.8639 15.4695C12.0537 17.5606 5.5 25.4797 5.5 34.9295C5.5 35.8107 5.557 36.6787 5.66751 37.5299C5.69022 37.7047 5.89478 37.7715 5.9855 37.6203C6.48459 36.7885 7.65194 36.6901 8.28325 37.4266L9.13275 38.4177C9.67104 39.0457 10.4986 39.3464 11.3145 39.2104L12.9712 38.9343C14.6324 38.6574 16.1961 39.802 16.4342 41.4692Z"
      stroke="#3F6655"
      strokeWidth="2"
    />
    <path
      d="M27.173 14.9984C26.5754 14.949 26 15.3299 26 15.9295V16.5691C26 17.1313 26.158 17.6823 26.456 18.1591L28.0392 20.6922C28.3259 21.1509 28.8286 21.4295 29.3694 21.4295C30.0446 21.4295 30.644 20.9974 30.8575 20.3569L31.0991 19.6323C31.3385 18.914 32.0107 18.4295 32.7679 18.4295C33.5076 18.4295 34.1683 17.9667 34.4211 17.2715C34.4644 17.1523 34.4119 17.0194 34.298 16.9635C32.1168 15.8934 29.7122 15.2087 27.173 14.9984Z"
      stroke="#3F6655"
      strokeWidth="2"
    />
    <path
      d="M38.8809 20.0648C38.2534 19.4996 37.2827 19.6771 36.8351 20.3933C36.6161 20.7437 36.5 21.1486 36.5 21.5618V22.0948C36.5 22.865 35.9432 23.5223 35.1834 23.6489C34.2762 23.8001 33.6909 24.6929 33.9139 25.5852L34.0811 26.254C34.3273 27.2387 35.2121 27.9295 36.2271 27.9295H39.4715C39.8211 27.9295 40.1681 27.8683 40.4967 27.7488L43.5252 26.6476C43.6119 26.616 43.65 26.5165 43.6108 26.433C42.4689 24.0033 40.8525 21.8407 38.8809 20.0648Z"
      stroke="#3F6655"
      strokeWidth="2"
    />
    <path
      d="M45.2181 31.5644C45.121 30.9913 44.6028 30.5619 44.0513 30.7457L40.9618 31.7755C40.656 31.8775 40.3356 31.9295 40.0132 31.9295H37.7426C36.947 31.9295 36.1839 32.2455 35.6213 32.8081L34.8787 33.5508C34.3161 34.1134 34 34.8765 34 35.6721V37.6868C34 38.4825 34.3161 39.2455 34.8787 39.8081L35.1791 40.1086C35.7047 40.6342 36.4176 40.9295 37.1609 40.9295C38.5154 40.9295 39.6761 41.8982 39.9184 43.2308L40.3613 45.6666C40.4523 46.1669 40.669 46.636 40.991 47.0296C41.1057 47.1697 41.3206 47.1679 41.4302 47.0237C43.9841 43.6651 45.5 39.4743 45.5 34.9295C45.5 33.7827 45.4035 32.6585 45.2181 31.5644Z"
      stroke="#3F6655"
      strokeWidth="2"
    />
  </svg>
);

export default GlobeIcon;
